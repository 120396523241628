import { MeatCut } from '.'

export const tiles: MeatCut[] = [
  {
    position: {
      left: 152,
      top: 89,
      width: 82,
      height: 83,
    },
    image: undefined,
    path: 'product-detailed/beef/2050.png',
    code: '2050',
    name: 'Outside flat',
    description: '',
  },
  {
    position: {
      left: 239,
      top: 101,
      width: 85,
      height: 73,
    },
    image: undefined,
    path: 'product-detailed/beef/2030.png',
    code: '2030',
    name: 'Outside',
    description: '',
  },
  {
    position: {
      left: 324,
      top: 110,
      width: 137,
      height: 66,
    },
    image: undefined,
    path: 'product-detailed/beef/2020.png',
    code: '2020',
    name: 'Silverside',
    description: '',
  },
  {
    position: {
      left: 144,
      top: 177,
      width: 90,
      height: 49,
    },
    image: undefined,
    path: 'product-detailed/beef/2040.png',
    code: '2040',
    name: 'Eye round',
    description: '',
  },
  {
    position: {
      left: 118,
      top: 228,
      width: 87,
      height: 62,
    },
    image: undefined,
    path: 'product-detailed/beef/2120.png',
    code: '2120',
    name: 'Top sirloin',
    description: '',
    hidden: true,
  },
  {
    position: {
      left: 261,
      top: 211,
      width: 91,
      height: 64,
    },
    image: undefined,
    path: 'product-detailed/beef/2090.png',
    code: '2090',
    name: 'Rump',
    description: '',
  },
  {
    position: {
      left: 107,
      top: 308,
      width: 101,
      height: 51,
    },
    image: undefined,
    path: 'product-detailed/beef/2100.png',
    code: '2100',
    name: 'D-Rump',
    description: '',
  },
  {
    position: {
      left: 214,
      top: 303,
      width: 90,
      height: 60,
    },
    image: undefined,
    path: 'product-detailed/beef/2093.png',
    code: '2093',
    name: 'Eye of rump',
    description: '',
    hidden: true,
  },
  {
    position: {
      left: 286,
      top: 313,
      width: 84,
      height: 90,
    },
    image: undefined,
    path: 'product-detailed/beef/2140.png',
    code: '2140',
    name: 'Striploin',
    description: '',
  },
  {
    position: {
      left: 112,
      top: 367,
      width: 86,
      height: 70,
    },
    image: undefined,
    path: 'product-detailed/beef/2275.png',
    code: '2275',
    name: 'Chuck roll',
    description: '',
  },
  {
    position: {
      left: 217,
      top: 395,
      width: 89,
      height: 68,
    },
    image: undefined,
    path: 'product-detailed/beef/2300.png',
    code: '2300',
    name: 'Blade (Clod)',
    description: '',
  },
  {
    position: {
      left: 111,
      top: 438,
      width: 98,
      height: 85,
    },
    image: undefined,
    path: 'product-detailed/beef/2270.png',
    code: '2270',
    name: 'Chuck - Square cut',
    description: '',
  },
  {
    position: {
      left: 122,
      top: 544,
      width: 106,
      height: 61,
    },
    image: undefined,
    path: 'product-detailed/beef/2280.png',
    code: '2280',
    name: 'Neck',
    description: '',
  },
  {
    position: {
      left: 235,
      top: 489,
      width: 117,
      height: 89,
    },
    image: undefined,
    path: 'product-detailed/beef/2260.png',
    code: '2260',
    name: 'Chuck',
    description: '',
  },
  {
    position: {
      left: 361,
      top: 544,
      width: 73,
      height: 64,
    },
    image: undefined,
    path: 'product-detailed/beef/2310.png',
    code: '2310',
    name: 'Chuck tender',
    description: '',
  },
  {
    position: {
      left: 573,
      top: 102,
      width: 110,
      height: 53,
    },
    image: undefined,
    path: 'product-detailed/beef/2000.png',
    code: '2000',
    name: 'Topside',
    description: '',
  },
  {
    position: {
      left: 668,
      top: 146,
      width: 68,
      height: 83,
    },
    image: undefined,
    path: 'product-detailed/beef/2060.png',
    code: '2060',
    name: 'Thick flank',
    description: '',
  },
  {
    position: {
      left: 749,
      top: 116,
      width: 72,
      height: 68,
    },
    image: undefined,
    path: 'product-detailed/beef/2070.png',
    code: '2070',
    name: 'Knuckle',
    description: '',
  },
  {
    position: {
      left: 716,
      top: 225,
      width: 102,
      height: 68,
    },
    image: undefined,
    path: 'product-detailed/beef/2150.png',
    code: '2150',
    name: 'Tenderloin (Side strap on)',
    description: '',
  },
  {
    position: {
      left: 618,
      top: 238,
      width: 89,
      height: 51,
    },
    image: undefined,
    path: 'product-detailed/beef/2210.png',
    code: '2210',
    name: 'Flank steak',
    description: '',
  },
  {
    position: {
      left: 625,
      top: 305,
      width: 90,
      height: 71,
    },
    image: undefined,
    path: 'product-detailed/beef/2220.png',
    code: '2220',
    name: 'Rib set',
    description: '',
    hidden: true,
  },
  {
    position: {
      left: 755,
      top: 301,
      width: 66,
      height: 80,
    },
    image: undefined,
    path: 'product-detailed/beef/2240.png',
    code: '2240',
    name: 'Cube roll',
    description: '',
    hidden: true,
  },
  {
    position: {
      left: 659,
      top: 391,
      width: 146,
      height: 52,
    },
    image: undefined,
    path: 'product-detailed/beef/1695.png',
    code: '1695',
    name: 'Short ribs (5 ribs)',
    description: '',
  },
  {
    position: {
      left: 618,
      top: 453,
      width: 98,
      height: 76,
    },
    image: undefined,
    path: 'product-detailed/beef/2320.png',
    code: '2320',
    name: 'Brisket',
    description: '',
    hidden: true,
  },
  {
    position: {
      left: 724,
      top: 450,
      width: 93,
      height: 76,
    },
    image: undefined,
    path: 'product-detailed/beef/2330.png',
    code: '2330',
    name: 'Brisket point end',
    description: '',
    hidden: true,
  },
  {
    position: {
      left: 717,
      top: 532,
      width: 93,
      height: 72,
    },
    image: undefined,
    path: 'product-detailed/beef/2350.png',
    code: '2350',
    name: 'Brisket point end (Deckle off)',
    description: '',
    hidden: true,
  },
  {
    position: {
      left: 603,
      top: 527,
      width: 94,
      height: 81,
    },
    image: undefined,
    path: 'product-detailed/beef/2340.png',
    code: '2340',
    name: 'Brisket navel end',
    description: '',
    hidden: true,
  },
]
