import * as React from 'react'
import Layout from '../../../components/Layout/Layout'
import { Container, Row, Col } from 'react-bootstrap'

import '../../../styles/styles.scss'
import { graphql } from 'gatsby'
import * as classNames from 'classnames'

import Img, { FluidObject } from 'gatsby-image'

import InquiryForm from '../../../components/InquiryForm/InquiryForm'
import { tiles } from '../../../dataMocks/beefCuts'
import { getImageByPath } from '../../../utils/image'

class ProductBeef extends React.Component {
  constructor(props: any) {
    super(props)

    this.state = {
      selected: null,
    }
  }

  render() {
    const {
      data: {
        allFile: { edges },
        file,
      },
    } = this.props
    const { selected } = this.state

    return (
      <Layout seo={{ title: 'Beef' }}>
        <section className="product-detailed">
          <Container>
            <Row>
              <Col>
                <h3 className="product-detailed__title">Beef</h3>
                <p className="product-detailed__subtitle">
                  Please click on any item to send us an inquiry
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={9}>
                <div className="product-detailed__map-wrapper">
                  <div className="product-detailed__map">
                    <Img fixed={file.childImageSharp.fixed} />
                  </div>
                  {tiles
                    .filter(tile => !tile.hidden)
                    .map((tile, i) => {
                      tile.image = getImageByPath<FluidObject>(edges, tile.path)
                      return (
                        <div
                          key={i}
                          className={classNames(
                            'meat-tile',
                            'meat-tile--beef',
                            {
                              selected: selected && selected.code === tile.code,
                            }
                          )}
                          style={{
                            ...tile.position,
                          }}
                          onClick={() => {
                            this.setState({ selected: tile })
                          }}
                        />
                      )
                    })}
                </div>
              </Col>

              <Col xs={3}>
                {selected && (
                  <React.Fragment>
                    <div className="meat-tile__details">
                      <div className="meat-tile__icon">
                        <Img fluid={selected.image} />
                      </div>
                      <div className="meat-tile__info">
                        <p className="meat-tile__code">{selected.code}</p>
                        <h4 className="meat-tile__name">{selected.name}</h4>
                        <p className="meat-tile__description">
                          {selected.description}
                        </p>
                      </div>
                    </div>
                    <InquiryForm
                      meat={selected}
                      redirectTo={`//${process.env.GATSBY_DOMAIN}/products/beef`}
                    />
                  </React.Fragment>
                )}
              </Col>
            </Row>
          </Container>
        </section>
      </Layout>
    )
  }
}

export default ProductBeef

export const query = graphql`
  query {
    file(relativePath: { eq: "product-detailed/detailed-beef@3x.png" }) {
      childImageSharp {
        fixed(width: 963, height: 647, quality: 80) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

    allFile(
      filter: { relativeDirectory: { regex: "/product-detailed/beef/" } }
    ) {
      edges {
        node {
          relativePath
          childImageSharp {
            fluid(maxWidth: 120, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
